<template>
    <div class="wrap">
        <div class="btn-box">
            <div id="btn" class="download" @click="openApp">打开客户端</div>
        </div>
    </div>
</template>
  
<script>

import Clipboard from 'clipboard'

export default {
    components: {},
    data() {
        return {
            siteInviteId: this.$route.query.siteInviteId,
        };
    },
    methods: {
        openApp() {
            let copyStr = this.siteInviteId + "邀请" + "  " + "https://m.sxdjcq.com/invite"
            this.copyContentH5(copyStr)
            var u = navigator.userAgent;
            
            var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; // Android
            if (isAndroid) {
                window.location = "sxyjuser://api.open.com.sxyj/app/user";
            } else {
                window.location = "sxyjuser://";//打开某手机上的某个app应用 
            }
            setTimeout(() => {
                //如果超时就跳转到app下载页
                let hidden = window.document.hidden || window.document.mozHidden || window.document.msHidden || window.document.webkitHidden;
                if (typeof hidden !== "undefined" && hidden === true) {
                    return;
                }
                let invite_path = 'agreement/download'
                this.$router.push({ path: invite_path });
            }, 2000);
        },
        copyContentH5ByIos(content) {
            console.log('content =', content);
            let clipBoard = new Clipboard('#btn', {
                text: () => {
                    return content
                }
            })
            console.log('clipBoard =', clipBoard);
            clipBoard.on('success', function (e) {
                clipBoard.destroy() // 销毁上一次的复制内容
                clipBoard = new Clipboard('#btn')
                console.log('复制成功', e);
            })
            clipBoard.on('error', function (e) {
                console.log('复制失败，请手动复制', e)
            })
        },
        copyContentH5(content) {
            var input = document.createElement('input')
            input.setAttribute('class', 'copy')
            input.setAttribute('style', 'position:absolute;left:-10000px;z-index:-1000')
            input.readOnly = true
            document.querySelector('body').appendChild(input)
            var copy = document.querySelector(".copy")
            copy.value = content
            if (navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {//区分iPhone设备
                window.getSelection().removeAllRanges();//这段代码必须放在前面否则无效
                var range = document.createRange();
                range.selectNode(copy);
                window.getSelection().addRange(range);
                var successful = document.execCommand('copy');
                window.getSelection().removeAllRanges();
            } else {
                copy.select(); // 选择对象
                document.execCommand("copy"); // 执行浏览器复制命令
            }
        }
    },
    mounted() { },
};

</script>
  
<style lang="less" scoped>
.wrap {
    position: relative;
    background: url("../assets/img/bg.png") center center no-repeat;
    /* background-size: 100% 100%; */
    background-size: cover;
    height: 100vh;
}

.wrap .btn-box {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    box-sizing: border-box;
}

.download {
    margin: 20px auto;
    width: 90%;
    padding: 16px 0;
    text-align: center;
    font-size: 18px;
    background-color: #32a2d3;
    border-radius: 30px;
    color: #ffffff;
}
</style>
  